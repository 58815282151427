<script setup lang="ts">
import { inject } from 'vue'

import { cn, groupStyleClasses } from '../../utils'
import { Loader } from '../loader'

import { buttonVariants, type ButtonProps } from '.'

withDefaults(defineProps<ButtonProps>(), {
  as: 'button',
  loading: false,
  rounded: false,
  disabled: false,
})
const isInputGroup = inject<boolean>('input-group', false)
</script>

<template>
  <component
    :is="as"
    :disabled="disabled || loading"
    :loading="loading"
    :type="$attrs.type ?? 'button'"
    :class="
      cn(
        buttonVariants({ variant, size }),
        groupStyleClasses(isInputGroup!),
        $attrs.class ?? '',
        $attrs.rounded ? 'rounded-full font-medium' : 'rounded-lg font-semibold',
        loading ? 'pointer-events-none cursor-wait' : ''
      )
    "
  >
    <Loader v-if="loading" :size="size" :variant="variant" />
    <component :is="icon" v-if="icon && !loading" />

    <slot />
  </component>
</template>
