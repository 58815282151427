<script setup lang="ts">
import NavigationFooter from '@components/navigation-footer.vue'
import { useClipboard } from '@vueuse/core'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import { Button } from '@epostbox/ui/button'
import { SidebarNavigation } from '@epostbox/ui/organisms/sidebar-navigation'
import { Paragraph } from '@epostbox/ui/paragraph'
import { Skeleton } from '@epostbox/ui/skeleton'

import { useAuthFlow } from '@composables/use-auth-flow'
import { useUser } from '@composables/use-user'

const route = useRoute()
const { locale, t } = useI18n()
const { copy } = useClipboard()

const { state, redirectedUrl } = useAuthFlow()
const { isLoading: isUserLoading } = useUser()

const isFullpage = computed(() => {
  return route?.meta.fullpage || false
})

const version = {
  build: import.meta.env.VITE_APP_BUILD_VERSION,
  date: import.meta.env.VITE_APP_BUILD_DATE,
}
</script>

<template>
  <div :key="locale" class="w-min-0 w-full grow overflow-hidden">
    <!-- loading skeleton -->
    <div v-if="state === 'pending' || isUserLoading" class="flex h-screen flex-row overflow-hidden bg-white">
      <Skeleton class="h-full w-[312px] rounded-lg" />
      <div class="flex flex-1 flex-col p-8">
        <Skeleton class="h-8 w-full rounded-lg" />
        <div class="mt-10 flex gap-x-2">
          <Skeleton class="h-20 w-20 rounded-lg" />
          <Skeleton class="h-20 w-20 rounded-lg" />
          <Skeleton class="h-20 w-20 rounded-lg" />
        </div>
        <Skeleton class="mt-8 h-96 w-full rounded-lg" />
      </div>
    </div>

    <!-- shows up only in the desktop app. -->
    <div
      v-else-if="state === 'redirected'"
      class="flex h-screen flex-col items-center justify-center overflow-hidden bg-white"
    >
      <Paragraph>
        {{ t('auth.redirected.message') }}
      </Paragraph>
      <Button type="button" data-e2e="copy-redirect" @click="copy(redirectedUrl!)">
        {{ t('auth.redirected.copy') }}
      </Button>
    </div>

    <div v-else id="app-view" class="flex h-screen flex-row bg-white font-bold">
      <SidebarNavigation v-if="!isFullpage" :key="route.path">
        <template #footer>
          <NavigationFooter :version="version" />
        </template>
      </SidebarNavigation>

      <section
        class="flex h-screen w-full flex-1 flex-col overflow-y-auto overflow-x-hidden bg-white transition-all duration-300 ease-in-out"
      >
        <slot />
      </section>
    </div>
  </div>
</template>
